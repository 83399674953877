import React from 'react'
import thumbsup from "../../assests/thumb1.png"
import thumbsup1 from "../../assests/thumb2.png"
import thumbsup2 from "../../assests/thumb3.png"
import thumbsup3 from "../../assests/thumsb4.png"
import thumbsup4 from "../../assests/thumbs5.png"
import thumbsup5 from "../../assests/thumbs6.png"


const Glory = () => {
    return (
        <div className='aboutus-glory'>
            <div className="heading-color">Our Clock of Glory </div>
            <div className="para">Time ticks faster everyday here!</div>
            <div className='year-glory'>
                <div className='year'>
                    <div className='nmu'>2</div>
                    <div className='nmu' >0</div>
                    <div className='nmu' >2</div>
                    <div className='nmu' >3</div>
                </div>
                <div>
                    <ul>
                        <li className='txt'>Won a <span>Silver award Marketers' Xcellence Awards</span></li>
                        <li className='txt'>Won <span>Gold awards at the mCube awards by Inkspell</span></li>
                        <li className='txt'>Won <span>Gold and 2 Bronze awards at the e4m Maverick awards</span></li>
                        <li className='txt'>Recognized among the <span>3% agencies to retain the Google Premier Partner status</span></li>
                        <li className='txt'>Received <span>Snapchat Marketing Partner status</span></li>
                        <li className='txt'>Received <span>Adroll Marketing Partner status</span></li>
                        <li className='txt'>Established a <span>new office in Pune with 500+ capacity</span></li>
                        <li className='txt'>Team strength <span> increased to 250+</span></li>
                        <li className='txt'><span>Established SRV Edge</span>, a dedicated wing for education industry</li>
                    </ul>
                </div>
                <div
                // className='img-capture'
                >
                    <img src={thumbsup} alt="thumbsup"
                    //  className='thumbsup'
                    />
                </div>
            </div>
            <div className='year-glory'>
                <div
                // className='img-captures'
                >
                    <img src={thumbsup1} alt="thumbsup"
                    // className='thumbsup' 
                    />
                </div>
                <div>
                    <ul>
                        <li className='txts'>Set up an <span>office in Ahmedabad</span></li>
                        <li className='txts'>Featured in the <span> Top 10 PR companies by Global Hues Magazine</span></li>
                        <li className='txts'>Received <span> Google International Growth Partner Status</span></li>
                        <li className='txts'>Awarded for the <span> Best Social Media Design</span>(Bronze) at the Marketer's Excellence Awards by afaqs!</li>
                        <li className='txts'><span>Awarded as the Integrated Marketing Agency of the Year (Silver)</span>at the prestigious Indian Agency Awards by Social Samosa</li>
                        <li className='txts'>Received <span>Adroll Marketing Partner status</span></li>
                        <li className='txts'><span>Awarded as the Mobile Marketing Agency of the Year</span>(Silver) at the prestigious Indian Agency Awards by Social Samosa</li>
                        <li className='txts'><span>Team size increased to 200+</span></li>
                    </ul>
                </div>
                <div className='year'>
                    <div className='nmu'>2</div>
                    <div className='nmu' >0</div>
                    <div className='nmu' >2</div>
                    <div className='nmu' >2</div>
                </div>
            </div>
            <div className='year-glory'>
                <div className='year'>
                    <div className='nmu'>2</div>
                    <div className='nmu' >0</div>
                    <div className='nmu' >2</div>
                    <div className='nmu' >1</div>
                </div>
                <div>
                    <ul>
                        <li className='txt'>Set up a  <span>dedicated wing for Education sector</span></li>
                        <li className='txt'>Set up an  <span>office in Kolkata</span></li>
                        <li className='txt'><span>Team Size Increased to 170+</span></li>
                        <li className='txt'>Won <span> 2 awards for Best Paid Campaign & Best Lead Generation Campaign at afaqs! Digies Digital Awards 2021</span></li>
                    </ul>
                </div>
                <div className='img-capture'>
                    <img src={thumbsup2} alt="thumbsup" className='thumbsup' />
                </div>
            </div>
            <div className='year-glory'>
                <div className='img-captures'>
                    <img src={thumbsup3} alt="thumbsup" className='thumbsup' />
                </div>
                <div>
                    <ul>
                        <li className='txts'>
                            <span>Facebook Blueprint certification</span>
                        </li>
                        <li className='txts'>
                            Became an  <span>ISO certified agency</span>
                        </li>
                        <li className='txts'>
                            Became one of the very few agencies in India to get<span>Facebook partner certification</span>
                        </li>
                        <li className='txts'>
                            More than  <span>30+ employees qualified with</span> Facebook
                        </li>
                        <li className='txts'>
                            Team size increased to  <span>130+ subject matter experts</span>
                        </li>
                    </ul>
                </div>
                <div className='year'>
                    <div className='nmu'>2</div>
                    <div className='nmu' >0</div>
                    <div className='nmu' >2</div>
                    <div className='nmu' >0</div>
                </div>
            </div>
            <div className='year-glory'>
                <div className='year'>
                    <div className='nmu'>2</div>
                    <div className='nmu' >0</div>
                    <div className='nmu' >1</div>
                    <div className='nmu' >9</div>
                </div>
                <div>
                    <ul>
                        <li className='txts'>
                            Set up an <span>office in Gurugram</span>
                        </li>
                        <li className='txts'>
                            One of the very few agencies to get <span>Google DV 360 access</span>
                        </li>
                        <li className='txts'>
                            Awarded as <span>“Outstanding Marketing Agency For Education Sector”</span> by Digital Learning
                        </li>
                        <li className='txts'>
                            Started using <span>AI & Machine Learning</span> for gathering data-driven insights
                        </li>
                    </ul>
                </div>
                <div className='img-capture'>
                    <img src={thumbsup4} alt="thumbsup" className='thumbsup' />
                </div>
            </div>
            <div className='year-glory'>
                <div className='img-captures'>
                    <img src={thumbsup5} alt="thumbsup" className='thumbsup' />
                </div>
                <div>
                    <ul>
                        <li className='txts'>
                            <span>Best Startup of the year award by ET Now</span>
                        </li>
                        <li className='txts'>
                            Set up an <span>office in Bengaluru</span>
                        </li>
                        <li className='txts'>
                            Team size increased to <span>100 talented employees</span>
                        </li>
                    </ul>
                </div>
                <div className='year'>
                    <div className='nmu'>2</div>
                    <div className='nmu' >0</div>
                    <div className='nmu' >1</div>
                    <div className='nmu' >8</div>
                </div>
            </div>
        </div>
    )
}

export default Glory